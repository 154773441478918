import { makeStyles } from 'tss-react/mui'
import { pxToRem } from '@styles/function'
import { nunito_sans } from '@styles/fonts'
// import bg from 'src/assets/images/partner/articleListBg.png'
import bg from 'src/assets/images/home/blobBg.jpg'

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    width: '100%',
    padding: theme.spacing(8, 0, 0, 0),
  },
  partnerRootContainer: {
    background: `url(${bg?.src})`,
    borderRadius: '50px',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('md')]: {
      borderRadius: '20px',
    },
  },

  zenModeHeading:{
    color:'black'
  },

  zenModeContainer: {
    background: 'none !important',
    '& *': {
      background: 'none !important',
    },
  },
  hideOnMd: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  hideOnSm: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  showOnSm: {
    width: '100%',
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  showOnMd: {
    width: '100%',
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  headingWrapper: {
    width: '100%',
    maxWidth: '1750px',
    margin: '0 auto',
    padding: theme.spacing(0, 17.5, 0, 17.5),
    background:
      'linear-gradient(180deg, #F2F2F2 39.42%, rgba(255, 255, 255, 0) 100%)',
    transform: 'rotate(-180deg)',
    '& >*': {
      transform: 'rotate(180deg)',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  partnerHeadingWrapper: {
    padding: theme.spacing(0, 17.5, 0, 17.5),
    color: 'rgba(150, 148, 248, 1)',
    background: 'none',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },

  Wrapper: {
    padding: theme.spacing(0, 17.5, 0),
    background: 'rgba(242, 242, 242, 1)',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
    width: '100%',
    maxWidth: '1750px',
    margin: '0 auto',
  },
  partnersWrapper: {
    padding: theme.spacing(0, 17.5, 0),
    background: 'none',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  secondaryHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: pxToRem(72),
    fontWeight: 800,
    lineHeight: pxToRem(83),
    letterSpacing: '0em',
    textAlign: 'left',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(32),
    },
  },
  filterTextField: {
    width: '100%',
    background: 'white',
    borderRadius: pxToRem(40),

    '& .MuiOutlinedInput-root': {
      borderBottomRightRadius: '40px',
      paddingRight: pxToRem(19.6),
      paddingLeft: pxToRem(24),
      borderColor: 'none',

      border: 'none',

      height: '60px',
      [theme.breakpoints.down('sm')]: {
        height: '61px',
        borderColor: 'none',
      },
      '& fieldset': {
        // borderColor: 'black',
        borderColor: 'none',
        color: 'black',

        height: '60px',
        [theme.breakpoints.down('sm')]: {
          height: '60px',
        },
      },
      '&:hover fieldset': {
        borderColor: 'none',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '0px',
        borderColor: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderBottomRightRadius: '40px',
        borderColor: 'none',
      },
    },
  },

  filterTextFieldWithZenMode: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'none',

      border: 'none',
      [theme.breakpoints.down('sm')]: {
        borderColor: 'none',
      },
      '& fieldset': {
        border: '1px solid black',

        height: '60px',
        [theme.breakpoints.down('sm')]: {
          height: '60px',
        },
      },
      '&:hover fieldset': {
        border: '1px solid black',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid black',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderBottomRightRadius: '40px',
        border: '1px solid black',
      },
    },
  },

  searchIconStyle: {
    width: pxToRem(20.73),
    height: pxToRem(20.73),
    '& *': {
      width: '100%',
      height: '100%',
    },
  },
  articleListContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '32.48% 32.48% 32.48%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '48.9% 48.9%',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
    gridGap: '20px',
  },
  searchContainer: {
    padding: 0,
  },
}))

export default useStyles
