/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { SearchIcon } from 'src/assets/svgs'
import useStyles from './Style'
import { RoundTextField } from 'src/components/shared/atoms'
import UsePagination from 'src/components/shared/atoms/pagination/Pagination'
import RoundSelect from 'src/components/shared/atoms/roundSelect/RoundSelect'
import HelplineCard from 'src/components/shared/modules/HelpLinesCard'
import Filter from 'src/assets/svgs/icons/filter'
import { DropDownData } from 'src/types/components/shared/atom'
import {
  useGetFavouriteArticleIdByUserQuery,
  useGetAlgoliaRecommendedArticlesQuery,
} from 'src/store/ApiCall/articleAPI'
import { useGetAllAuthorsQuery } from 'src/store/ApiCall/authorAPI'
import { useGetTopicsListQuery } from 'src/store/ApiCall/topicsAPI'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import algoliasearch from 'algoliasearch/lite'
import {
  checkSearchQuery,
  preprocessAlgoliaRecommendedArticles,
} from 'src/utils'
import SearchResultContainer from 'src/components/shared/modules/searchResultContainer'
import { useRouter } from 'next/router'
import orderBy from 'lodash.orderby'
import Modal from 'src/components/shared/modules/modal'
import { useSensitiveWordsQuery } from 'src/store/ApiCall/sensitiveWordAPI'
import { SensitiveWordsDataType } from 'src/types'

import { v4 as uuid } from 'uuid'
import { getCookie } from 'cookies-next'
import { AlgoliaRecommendArticle } from 'src/types/store'
interface Props {
  zenMode: boolean
  tagQuery?: string
  isFavouriteList?: boolean
  content: string
  partnersPageActive?: boolean
}

interface AuthorsData {
  id: number
  name: string
}
interface TopicsData {
  id: number
  name: string
}

const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP || '',
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
)

// let idxName = ''
let newest_article = ''
let oldest_article = ''
let most_commented_article = ''

if (typeof window === 'object' && window.location.href.includes('mx')) {
  // idxName = 'mx_search_articles'
  newest_article = 'mx_search_articles_newest'
  oldest_article = 'mx_search_articles_oldest'
  most_commented_article = 'mx_search_articles_most_commented'
} else {
  // idxName = 'search_articles'
  newest_article = 'search_articles_newest'
  oldest_article = 'search_articles_oldest'
  most_commented_article = 'search_article_most_commented'
}

// const searchIndex = client.initIndex(idxName)
const searchArticlesNewest = client.initIndex(newest_article)
const searchArticlesOldest = client.initIndex(oldest_article)
const likesArticleIndex = client.initIndex('search_articles_likes_rep')
const mostCommentedArticleIndex = client.initIndex(most_commented_article)
const anonymousUserToken = getCookie('anonymousUserToken')

const ArticleList: React.FC<Props> = ({
  zenMode,
  tagQuery,
  isFavouriteList,
  content,
  partnersPageActive,
}) => {
  const activeTopicId = useSelector(
    (state: RootState) => state.activeTopics.activeTopic
  )
  // const unique_id = uuid()
  const loggedInUserJSON =
    typeof window === 'object' ? localStorage.getItem('dtl_token') : null
  const [recommendations, setRecommendations] = useState<
    AlgoliaRecommendArticle[]
  >([])
  const { data: algoliaRecommendedArticle } =
    useGetAlgoliaRecommendedArticlesQuery()
  React.useEffect(() => {
    if (algoliaRecommendedArticle?.data?.length) {
      const recommendedArticle = preprocessAlgoliaRecommendedArticles(
        algoliaRecommendedArticle
      )
      setRecommendations(recommendedArticle)
    }
  }, [algoliaRecommendedArticle])
  const [loggedInUser, setLoggedInUser] = React.useState(
    loggedInUserJSON ? Number(loggedInUserJSON) : -1
  )
  const { data: favArticleId } =
    useGetFavouriteArticleIdByUserQuery(loggedInUser)
  const [lang, setLang] = React.useState<string>()
  React.useEffect(() => {
    if (
      typeof window === 'object' &&
      (window.location.href.includes('mx') ||
        window.location.href.includes('quit') ||
        window.location.href.includes('quiat'))
    ) {
      localStorage.setItem('lang', 'Es')
      setLang('Es')
    } else {
      localStorage.setItem('lang', 'En')
      setLang('En')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window === 'object' ? window.location.href : null])
  const [, setIndexName] = React.useState('search_articles')

  React.useEffect(() => {
    if (lang != 'En') {
      setIndexName('mx_search_articles')
    } else {
      setIndexName('search_articles')
    }
  }, [lang])

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON =
        typeof window === 'object' ? localStorage.getItem('dtl_token') : null

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  const router = useRouter()

  // const [carouselData, setCarouselData] = useState<carouselType[]>([])
  const [authorsList, setAuthorsList] = useState<AuthorsData[]>([])
  const [topicsList, setTopicsList] = useState<TopicsData[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [searchText, setSearchText] = useState<string>('')
  const [queryID, setQueryID] = useState<string | undefined>(undefined)
  const [searchMode, setSearchMode] = React.useState(false)
  const [articlePage, setArticlePage] = React.useState<number>(1)
  const [searchArticles, setSearchArticles] = React.useState<any>()
  const [sensitiveWords, setSensitiveWords] = React.useState<string[]>([]) // TODO: UPDATE searchArticles type
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [searchOptions, setSearchOptions] = React.useState<any>({
    page: articlePage,
    hitsPerPage: 21,
    clickAnalytics: true,
  })
  const [activeTopic, setActiveTopic] = useState<{ id: number; name: string }>({
    id: -1,
    name: '',
  })
  const [activeOrder, setActiveOrder] = useState<{ id: number; name: string }>({
    id: 0,
    name: lang === 'Es' ? 'Más recientes' : 'Newest',
  })
  const userToken: string | null =
    typeof window === 'object' ? localStorage.getItem('dtl_token') : null
  const uID = uuid()

  React.useEffect(() => {
    if (isFavouriteList && favArticleId && favArticleId.length) {
      const stringIds = favArticleId.map((number) => number.toString())
      const filterExpression = stringIds
        .map((id) => `objectID:${id}`)
        .join(' OR ')
      setSearchOptions({
        page: articlePage - 1,
        hitsPerPage: 21,
        clickAnalytics: true,
        filters: filterExpression,
        enablePersonalization: true,
        userToken: userToken
          ? '' + JSON.parse(userToken).id
          : anonymousUserToken && typeof anonymousUserToken === 'string'
          ? anonymousUserToken
          : uID,
      })
    } else {
      setSearchOptions({
        page: articlePage - 1,
        hitsPerPage: 21,
        clickAnalytics: true,
        enablePersonalization: true,
        userToken: userToken
          ? '' + JSON.parse(userToken).id
          : anonymousUserToken && typeof anonymousUserToken === 'string'
          ? anonymousUserToken
          : uID,
      })
    }
  }, [isFavouriteList, favArticleId, articlePage])
  useEffect(() => {
    if (activeTopicId) {
      const result = topicsList.find((curr) => curr.id == activeTopicId.id)
      if (result) {
        setActiveTopic(result)
      }
    }
  }, [activeTopicId, topicsList])
  const [activeAuthor, setActiveAuthor] = useState<{
    id: number
    name: string
  }>({
    id: -1,
    name: '',
  })

  const { data: authorsData, isLoading: authorAPILoADING } =
    useGetAllAuthorsQuery()
  const { data: topicsData, isLoading: dataTopicAPILoading } =
    useGetTopicsListQuery()
  const { data: sensitiveApiRes } = useSensitiveWordsQuery(undefined, {
    skip: !!authorAPILoADING && !!dataTopicAPILoading,
  })

  const [algoliaQuery, setAlgoliaQuery] = useState<string>()

  useEffect(() => {
    if (searchText) setAlgoliaQuery(searchText)
    if (activeTopic) setAlgoliaQuery(searchText.concat(' - ', activeTopic.name))
    if (activeAuthor.name)
      setAlgoliaQuery(
        searchText.concat(' - ', activeTopic.name, ' - ', activeAuthor.name)
      )
  }, [searchText, activeAuthor, activeTopic])

  useEffect(() => {
    setPage(0)
  }, [activeAuthor, activeTopic, activeOrder])

  useEffect(() => {
    if (authorsData && authorsData.data.length) {
      const authorsArr = authorsData.data.map((curr) => {
        return {
          id: curr.id,
          name: curr.attributes.Name,
        }
      })
      setAuthorsList(authorsArr)
    }
  }, [authorsData])

  // ! get all topics list for filter
  useEffect(() => {
    if (topicsData && topicsData.data.length) {
      const topicsArr = topicsData.data.map((curr) => {
        return {
          id: curr.id,
          name: curr.attributes.topic,
        }
      })
      setTopicsList(topicsArr)
    }
  }, [topicsData])

  // ! matching data and setting active topic ID
  useEffect(() => {
    if (tagQuery && topicsList) {
      const tagToQuery =
        tagQuery == 'StopAsianHate' ? '#StopAsianHate' : tagQuery

      const res = topicsList.filter(
        (item) =>
          item.name.replace(/\s/g, '-').trim().toLowerCase() ==
          tagToQuery.replace(/\s/g, '-').trim().toLowerCase()
      )
      if (res[0]) {
        setActiveTopic(res[0])
      }
    }
  }, [tagQuery, topicsList])

  // ! Algolia search based on searchText, article page(pagination) and other parameters an optimal solution
  useEffect(() => {
    if (!algoliaQuery) {
      setSearchMode(false)
      return
    }
    if (checkSearchQuery(algoliaQuery)) {
      // by default newest is selected
      let activeIndex = searchArticlesNewest

      if (
        activeOrder.name === 'Most Popular' &&
        recommendations &&
        recommendations.length
      ) {
        const recommendedArticle = recommendations
          .filter((el: { author: string; tags: string[]; title: string }) => {
            if (
              activeAuthor.name &&
              activeAuthor.id !== -1 &&
              activeAuthor.name.toLowerCase() !== el.author.toLowerCase()
            )
              return false
            if (
              activeTopic.name &&
              activeTopic.id !== -1 &&
              activeTopic.name !==
                el.tags.find(
                  (curr: string) =>
                    curr.toLowerCase() == activeTopic.name.toLowerCase()
                )
            )
              return false
            if (
              searchText &&
              !el.title.toLowerCase().includes(searchText.toLowerCase())
            )
              return false
            return true
          })
          .map((curr: any) => {
            return {
              ...curr,
              liked:
                favArticleId &&
                favArticleId.length &&
                favArticleId.find((el) => el == curr.id)
                  ? true
                  : false,
            }
          })
        setSearchArticles(recommendedArticle)
        return
      }

      if (activeOrder.name == 'Most liked') activeIndex = likesArticleIndex
      else if (activeOrder.name == 'oldest') activeIndex = searchArticlesOldest
      else if (activeOrder.name == 'Most Commented')
        activeIndex = mostCommentedArticleIndex
      else activeIndex = searchArticlesNewest

      const algoliaSearchQuery = setTimeout(() => {
        activeIndex
          .search(algoliaQuery.trim(), {
            ...searchOptions,
            // ranking: [
            //   activeOrder.name == 'Most Popular' ||
            //     activeOrder.name == 'Most liked'
            //     ? 'desc(likes_count)'
            //     : activeOrder.name == 'oldest'
            //       ? 'asc(pubblishedAt)'
            //       : activeOrder.name == 'Most Commented'
            //         ? 'desc(comments_count)'
            //         : 'desc(pubblishedAt)',
            // ],
          })
          .then(({ hits, queryID, nbPages, page }) => {
            setPageCount(nbPages)
            setQueryID(queryID)
            setSearchArticles(hits)
            setPage(page)
            if (hits.length > 0) {
              setSearchMode(true)
            }
          })
      }, 500)

      return () => clearTimeout(algoliaSearchQuery)
    } else {
      setSearchMode(false)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    algoliaQuery,
    articlePage,
    pageCount,
    page,
    activeOrder.name,
    activeAuthor.name,
    activeTopic.name,
    recommendations,
    favArticleId,
    searchText,
    searchOptions,
  ])

  React.useEffect(() => {
    // call api here and set the sensitive word list
    if (sensitiveApiRes && sensitiveApiRes.data.length) {
      setSensitiveWords(
        sensitiveApiRes.data.map(
          (i: SensitiveWordsDataType) => i.attributes.word
        )
      )
    } else {
      setSensitiveWords([
        'hostage',
        'abuse',
        'depress',
        'depression',
        'suicide',
      ])
    }
  }, [sensitiveApiRes])

  const browseByTopicsArr = topicsList
    .filter((element) => {
      return (
        element.name.toLowerCase() !== 'lol' &&
        element.name.toLowerCase() !== 'all'
      )
    })
    .map((curr) => {
      return {
        value: String(curr.id),
        disabled: false,
        content: curr.name,
      }
    })

  const browseByTopicsContent = orderBy(browseByTopicsArr, ['content'], ['asc'])
  browseByTopicsContent.unshift({
    value: 'none',
    disabled: true,
    content: lang === 'En' ? 'Browse by topics' : 'Explorar por temas',
  })

  const articleByContent = authorsList.map((curr) => {
    return {
      value: String(curr.id),
      disabled: false,
      content: curr.name,
    }
  })
  articleByContent.unshift({
    value: '-1',
    disabled: false,
    content: lang === 'En' ? 'All' : 'Todos',
  })
  articleByContent.unshift({
    value: 'none',
    disabled: true,
    content: lang === 'En' ? 'Articles by' : 'Artículos por',
  })

  const orderyByContent = [
    {
      value: 'none',
      disabled: true,
      content: 'Sort By',
    },
    {
      value: '0',
      disabled: false,
      content: 'Newest',
    },
    {
      value: '1',
      disabled: false,
      content: 'Most Popular',
    },
    {
      value: '2',
      disabled: false,
      content: 'Most Liked',
    },
    {
      value: '3',
      disabled: false,
      content: 'Most Commented',
    },
    {
      value: '4',
      disabled: false,
      content: 'oldest',
    },
  ]
  const orderyBySpanishContent = [
    {
      value: 'none',
      disabled: true,
      content: 'Ordenar por',
    },
    {
      value: '0',
      disabled: false,
      content: 'Más recientes',
    },
    {
      value: '1',
      disabled: false,
      content: 'Más populares',
    },
    {
      value: '2',
      disabled: false,
      content: 'El mas gustado',
    },
    {
      value: '3',
      disabled: false,
      content: 'Más comentados',
    },
    {
      value: '4',
      disabled: false,
      content: 'Más antiguos',
    },
  ]

  const dropDownContent: DropDownData = {
    browseByTopics: browseByTopicsContent,
    articlesBy: articleByContent,
    newest: lang === 'En' ? orderyByContent : orderyBySpanishContent,
  }

  const handlePaginationClick = (page: number | null) => {
    if (page) {
      setPage(page)
    }
  }

  const handleTopicsChange = (e: { target: { value: string } }) => {
    const activeTopics = topicsList.find(
      (curr) => curr.id == Number(e.target.value)
    )
    if (activeTopics) {
      setActiveTopic(activeTopics)
    } else {
      setActiveTopic({
        id: -1,
        name: 'All',
      })
    }
  }

  const handleAuthorChange = (e: { target: { value: string } }) => {
    const activeAuthor = authorsList.find(
      (curr) => curr.id == Number(e.target.value)
    )
    if (activeAuthor) {
      setActiveAuthor(activeAuthor)
    } else {
      setActiveAuthor({
        id: -1,
        name: 'All',
      })
    }
  }

  const handleOrderChange = (e: { target: { value: string } }) => {
    const activeOrderData = orderyByContent.find(
      (curr) => curr.value == e.target.value
    )

    if (activeOrderData) {
      setActiveOrder({
        id: Number(activeOrderData.value),
        name: activeOrderData.content,
      })
    } else {
      setActiveAuthor({
        id: 0,
        name: 'Newest',
      })
    }
  }

  useEffect(() => {
    if (router?.query?.author && authorsList?.length) {
      const activeAuthor = authorsList.filter(
        (curr) => curr?.name == router?.query?.author
      )
      if (activeAuthor?.length > 0) {
        setActiveAuthor(activeAuthor[0])
      } else {
        setActiveAuthor({
          id: -1,
          name: 'All',
        })
      }
    }
  }, [router?.query, authorsList])

  const { classes } = useStyles()

  return (
    <>
      <Grid
        container
        className={`${classes.rootContainer} ${
          zenMode && classes.zenModeContainer
        } ${partnersPageActive && classes.partnerRootContainer}`}
      >
        <Grid
          container
          alignItems="center"
          className={`${classes.headingWrapper} ${
            partnersPageActive && classes.partnerHeadingWrapper
          } ${zenMode && classes.zenModeHeading}`}
        >
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={11} md={8}>
              <Typography
                className={`${classes.secondaryHeading}`}
                component="h3"
              >
                {content}
              </Typography>
            </Grid>
            <Grid className={classes.showOnMd} item xs={1}>
              <Filter />
            </Grid>
            <Grid className={classes.hideOnMd} item container md={4}>
              <Grid
                item
                justifyContent="flex-end"
                alignItems="center"
                container
                direction="row"
              ></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={`${classes.Wrapper} ${
            partnersPageActive && classes.partnersWrapper
          }`}
        >
          <Grid
            container
            sx={{
              marginTop: { md: '38px', sm: '0px', xs: '0px' },
              marginBottom: '50px',
            }}
            spacing={6}
            justifyContent="space-between"
          >
            <Grid item container md={12} direction="row" spacing={3}>
              <Grid item md={3} sm={6} xs={12}>
                <RoundTextField
                  variant="outlined"
                  fullWidth
                  placeholder={
                    lang === 'En' ? 'Search articles' : 'Buscar artículos'
                  }
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                    // sensitive word check
                    if (sensitiveWords) {
                      for (const i of e.target.value.split(' ')) {
                        if (sensitiveWords.includes(i)) {
                          setModalOpen(!modalOpen)
                          setSensitiveWords(
                            sensitiveWords.filter((e) => e !== i)
                          )
                        }
                      }
                    }
                  }}
                  className={`${classes.filterTextField} ${
                    zenMode && classes.filterTextFieldWithZenMode
                  }`}
                  InputProps={{
                    endAdornment: (
                      <Box className={classes.searchIconStyle}>
                        <SearchIcon />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <RoundSelect
                  value={activeTopic.id}
                  zenMode={zenMode}
                  content={dropDownContent['browseByTopics']}
                  onChange={(e) => handleTopicsChange(e)}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <RoundSelect
                  zenMode={zenMode}
                  value={activeAuthor.id}
                  content={dropDownContent['articlesBy']}
                  onChange={(e) => handleAuthorChange(e)}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Grid item flex={1}>
                  <RoundSelect
                    zenMode={zenMode}
                    value={activeOrder.id}
                    onChange={(e) => handleOrderChange(e)}
                    content={dropDownContent['newest']}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {searchMode && searchArticles.length ? (
            <SearchResultContainer
              articlesData={searchArticles}
              searchMode={searchMode}
              articlePage={articlePage}
              pagesCount={pageCount}
              resultsOnly={true}
              zenMode={zenMode}
              queryID={queryID}
              showPagination={
                activeOrder.name === 'Most Popular' ? false : true
              }
              articlePageHandler={(current: number | null) => {
                if (current) {
                  setArticlePage(current)
                }
              }}
              styles={classes.searchContainer}
            />
          ) : (
            'No Article Found'
          )}
          <Grid
            className={classes.hideOnSm}
            container
            justifyContent="flex-end"
            sx={{ margin: '50px 0px' }}
          >
            {!searchMode &&
            pageCount > 1 &&
            activeOrder.name !== 'Most Popular' ? (
              <UsePagination
                pageCount={pageCount}
                handleClick={handlePaginationClick}
                page={page}
              />
            ) : (
              ''
            )}
          </Grid>
          {/* {page < pageCount ? (
          <Grid
            className={classes.showOnMd}
            container
            sx={{ margin: '20px 0px 50px 0px' }}
          >
            <Grid item container justifyContent="center">
              <ButtonComponent
                onClick={() => setPage(page + 1)}
                type="outlined"
                text="LOAD MORE"
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )} */}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Modal closeHandler={() => setModalOpen(!modalOpen)}>
          <Grid
            container
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <HelplineCard onClickHandler={() => setModalOpen(!modalOpen)} />
          </Grid>
        </Modal>
      ) : null}
    </>
  )
}

export default ArticleList
